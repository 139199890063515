exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capital-markets-js": () => import("./../../../src/pages/capital-markets.js" /* webpackChunkName: "component---src-pages-capital-markets-js" */),
  "component---src-pages-china-and-international-js": () => import("./../../../src/pages/china-and-international.js" /* webpackChunkName: "component---src-pages-china-and-international-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-governance-director-skills-js": () => import("./../../../src/pages/corporate-governance-director-skills.js" /* webpackChunkName: "component---src-pages-corporate-governance-director-skills-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-experience-js": () => import("./../../../src/pages/industry-experience.js" /* webpackChunkName: "component---src-pages-industry-experience-js" */),
  "component---src-pages-investment-banking-js": () => import("./../../../src/pages/investment-banking.js" /* webpackChunkName: "component---src-pages-investment-banking-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-public-speaking-and-testimony-js": () => import("./../../../src/pages/public-speaking-and-testimony.js" /* webpackChunkName: "component---src-pages-public-speaking-and-testimony-js" */),
  "component---src-pages-recent-publications-js": () => import("./../../../src/pages/recent-publications.js" /* webpackChunkName: "component---src-pages-recent-publications-js" */),
  "component---src-pages-recent-scholarly-impact-js": () => import("./../../../src/pages/recent-scholarly-impact.js" /* webpackChunkName: "component---src-pages-recent-scholarly-impact-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-venture-capital-js": () => import("./../../../src/pages/venture-capital.js" /* webpackChunkName: "component---src-pages-venture-capital-js" */),
  "component---src-pages-washington-experience-js": () => import("./../../../src/pages/washington-experience.js" /* webpackChunkName: "component---src-pages-washington-experience-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

